:root {
    --color-purple: #663E73;
    --color-purple-rgb: 102,62,115;
    --color-purple-h: 285;
    --color-purple-s: 30%;
    --color-purple-l: 35%;
    
    --color-teal: #00A8A0;
    --color-teal-rgb: 0,168,160;
    --color-teal-h: 177;
    --color-teal-s: 100%;
    --color-teal-l: 33%;
    
    --color-orange: #F27621;
    --color-orange-rgb: 242,118,33;
    --color-orange-h: 24;
    --color-orange-s: 89%;
    --color-orange-l: 54%;
    
    --color-light-blue: #00BCF2;
    --color-light-blue-rgb: 0,188,242;
    --color-light-blue-h: 193;
    --color-light-blue-s: 100%;
    --color-light-blue-l: 47%;
    
    --color-yellow: #FFCC05;
    --color-yellow-rgb: 255,204,5;
    
    --color-light-gray: #C6C8CA;
    --color-light-gray-rgb: 198,200,202;
    
    --color-dark-gray: #727477;
    --color-dark-gray-rgb: 114,116,119;
    
    --color-dark-blue: #004E77;
    --color-dark-blue-rgb: 0,78,119;
    --color-dark-blue-h: 201;
    --color-dark-blue-s: 100%;
    --color-dark-blue-l: 23%;
    
    --color-black: #001919;
    --color-black-rgb: 0,25,25;
    
    --color-gold: #B99300;
    --color-gold-rgb: 185,147,0;
    
    --color-med-blue: #00A0CE;
    --color-med-blue-rgb: 0,160,206;
    
    --color-red: #AF0000;
    --color-red-rgb: 175,0,0;
    
    --color-darken: - 5%;
    --color-lighten: + 5%;
    
    --border-radius: .5rem;
    --border-radius-sm: .25rem;
    --border-radius-lg: 1rem;

    --border-width: 3px;

    --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    --box-shadow-sm: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    --box-shadow-lg: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}