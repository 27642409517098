@font-face {
    font-family: "Gotham";
    font-weight: 500;
    font-style: normal;
    src: url('../assets/fonts/Gotham-Book.otf');
}
// @font-face {
//     font-family: "Gotham";
//     font-weight: 500;
//     font-style: italic;
//     src: url('/assets/fonts/Gotham-BookItalic.otf');
// }
@font-face {
    font-family: "Gotham";
    font-weight: 600;
    font-style: normal;
    src: url('../assets/fonts/Gotham-Medium.otf');
}
// @font-face {
//     font-family: "Gotham";
//     font-weight: 600;
//     font-style: italic;
//     src: url('/assets/fonts/Gotham-MediumItalic.otf');
// }
@font-face {
    font-family: "Gotham";
    font-weight: 700;
    font-style: normal;
    src: url('../assets/fonts/Gotham-Bold.otf');
}
// @font-face {
//     font-family: "Gotham";
//     font-weight: 700;
//     font-style: italic;
//     src: url('/assets/fonts/Gotham-BoldItalic.otf');
// }
@font-face {
    font-family: "Gotham";
    font-weight: 900;
    font-style: normal;
    src: url('../assets/fonts/Gotham-Ultra.otf');
}
// @font-face {
//     font-family: "Gotham";
//     font-weight: 900;
//     font-style: italic;
//     src: url('/assets/fonts/Gotham-UltraItalic.otf');
// }