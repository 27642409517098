@import "./variables.scss";
@import "./bootstrap.scss";
@import "./fonts.scss";
@import "./root.scss";

@include media-breakpoint-up(sm) {
  .rounded-sm {
    border-radius: $border-radius;
  }
}

html {
  user-select: none;
  overscroll-behavior: none;
}

body {
  overscroll-behavior: none;
  -webkit-tap-highlight-color: transparent;
}
body.modal-open {
  overflow: hidden;
}

h1, h2, h3, h4, h5, button, p, div, span, input, select, header, footer, main, nav, ul, ol, li, dialog {
  letter-spacing: -0.025em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.modal-content {
  box-shadow: 0 1rem 2rem rgba(#000, 0.3);
}
.sr-only {
  display: none;
}
.close {
  padding: 0 0.5rem;
  margin: 0;
  background: none;
  appearance: none;
  font-size: 1.5rem;
  color: $gray-400;
  border: none;
}
.cursor-pointer {
  cursor: pointer;
}
// .offcanvas {
//     box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
// }
.list-group-item-action {
  cursor: pointer;
}
.modal-backdrop {
  backdrop-filter: blur(5px);
}
.modal-header .btn-close {
  z-index: 1;
}
.modal-footer {
  padding: 0.5rem;
  justify-content: flex-start;
}
.toast-header {
  padding: 0 0.75rem;
  padding-right: 0.25rem;
}
.form-group {
  margin-bottom: 0.5rem;
}
.form-label {
  line-height: 1.25;
}
.invalid-feedback {
  line-height: 1.25;
}
.badge-primary {
  background: $primary;
}
.badge-light {
  background: $light;
  color: $dark;
}
img,
object,
embed,
figure,
iframe {
  max-width: 100%;
  margin: 0;
}
.btn-primary {
  color: #fff !important;
}
.form-check-lg {
  min-height: 1.5rem;
  padding-left: 2rem;
  margin-bottom: 1rem;
  .form-label {
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-top: -0.0625rem;
  }
  .form-check-input {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0;
    min-height: auto;
    margin-left: -2rem;
    margin-top: 0;
  }
}

.text-muted {
  opacity: 66.6666%;
}

// Letter spacing
.ls-0 {
  letter-spacing: 0 !important;
}
.ls-1 {
  letter-spacing: 0.05em !important;
}
.ls-2 {
  letter-spacing: 0.1em !important;
}
.ls-3 {
  letter-spacing: 0.2em !important;
}

.btn.btn-skew {
  position: relative !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0.75rem 1.5rem !important;
  z-index: 1;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: skew(-15deg);
    display: block;
    content: " ";
    z-index: -1;
    border-radius: $border-radius;
    transition: background-color 0.2s;
  }
  &.btn-primary:after {
    background-color: $primary;
  }
  &.btn-secondary:after {
    background-color: $secondary;
  }
  &.btn-dark:after {
    background-color: $dark;
  }
  &.btn-light:after {
    background-color: $light;
  }
  &.btn-white:after {
    background-color: $white;
  }
  &.btn-sm {
    padding: 0.5rem 1rem !important;
    &:after {
      left: -0.25rem;
      right: -0.25rem;
      border-radius: $border-radius-sm;
    }
  }
  &.btn-lg {
    padding: 1rem 2rem !important;
    &:after {
      left: -0.5rem;
      right: -0.5rem;
      border-radius: $border-radius-lg;
    }
  }
  &.btn-transparent {
    &:after {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
    &:hover:after {
      background-color: rgba(255, 255, 255, 0.25) !important;
    }
  }
}

.box-purple {
  background: linear-gradient(
    to bottom right, 
    var(--color-purple), 
    hsl(
      var(--color-purple-h),
      var(--color-purple-s),
      calc(
        var(--color-purple-l) - 5%
      )
    )
  );
}

.box-blue {
  background: linear-gradient(
    to bottom right, 
    var(--color-dark-blue), 
    hsl(
      var(--color-dark-blue-h),
      var(--color-dark-blue-s),
      calc(
        var(--color-dark-blue-l) - 5%
      )
    )
  );
}

.box-teal {
  background: linear-gradient(
    to bottom right,
    var(--color-teal),
    hsl(
      var(--color-teal-h),
      var(--color-teal-s),
      calc(
        var(--color-teal-l) - 5%
      )
    )
  )
}
