// gray-color-variables
$white: #fff !default;
$gray-100: #f2f2f2; // #f8f9fa !default;
$gray-200: #e5e5e5; // #e9ecef !default;
$gray-300: #d9d9d9; // #dee2e6 !default;
$gray-400: #cccccc; // #ced4da !default;
$gray-500: #999999; // #adb5bd !default;
$gray-600: #666666; // #6c757d !default;
$gray-700: #404040; // #495057 !default;
$gray-800: #333333; // #343a40 !default;
$gray-900: #1a1a1a; // #212529 !default;
$black: #000 !default;
// color-variables
$blue: #00a0ce; // #1b95e0; // #0d6efd !default;
$indigo: #663e73; // #6610f2 !default;
$purple: #663e73; // #6f42c1 !default;
$red: #af0000; // #dc3545 !default;
$orange: #f27621; // #ba9301; // #fd7e14 !default;
$yellow: #ffcc05; // #ba9301; // #ffc107 !default;
$green: #15ad01; // #198754 !default;
$teal: #00a8a0; // #20c997 !default;
$cyan: #0c88af; // #0dcaf0 !default;
$gold: #B99300;

$color-purple: #663E73;
$color-teal: #00A8A0;
$color-orange: #F27621;
$color-light-blue: #00BCF2;
$color-yellow: #FFCC05;
$color-light-gray: #C6C8CA;
$color-dark-gray: #727477;
$color-dark-blue: #004E77;
$color-black: #001919;
$color-gold: #B99300;
$color-med-blue: #00A0CE;
$color-red: #AF0000;

// linear-gradient(to bottom right, #663E73, #9776A2);

$body-bg: #fefefe;
$primary: $blue;
$danger: $red;
$info: $cyan;
$success: $green;
$warning: $orange;
$dark: #044e77;
$light: $gray-200; // #e9ecef;
$enable-rfs: false;
$enable-negative-margins: true;
$enable-validation-icons: false;
$link-decoration: none;
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700; // 700
$font-weight-bolder: 900;
$headings-font-weight: $font-weight-bold;
$form-label-margin-bottom: 0; // 0.25rem;
$alert-padding-x: 0.5rem;
$alert-padding-y: 0.5rem;
$card-border-width: 0;
$modal-header-padding: 0.5rem 1rem;
$modal-content-border-width: 0;
$modal-backdrop-opacity: 0.75;
$form-feedback-margin-top: 0.25rem;
$input-bg: #fff;
$input-btn-focus-width: 0;
$input-btn-focus-box-shadow: none;
$form-check-input-focus-box-shadow: none;
$input-placeholder-color: $gray-500;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$enable-validation-icons: false;
$modal-content-border-width: 0;
$enable-smooth-scroll: false;
$enable-cssgrid: true;
// $border-color: $light;
$text-muted: inherit; // $gray-500; // #adb5bd;
$carousel-control-color: $black;
$carousel-control-opacity: 1;
$carousel-indicator-width: 0; // 0.5rem;
$carousel-indicator-height: 0; // 0.5rem;
$carousel-indicator-hit-area-height: 0; // 1rem;
$carousel-indicator-spacer: 0; // 0.5rem;
$btn-close-opacity: 0.75;
$btn-font-weight: 600;
$btn-close-hover-opacity: 1;
$font-family-sans-serif: 'Gotham', 'Gotham Book', 'Gotham-Book', Arial, sans-serif;
$font-size-base: 1rem !default;
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
$headings-color: $dark;
$hr-color: $gray-200;
$hr-border-width: 3px;
$hr-opacity: 1;

$border-radius: 0.5rem;
$border-radius-sm: 0.5rem;
$border-radius-lg: 0.5rem;
$border-radius-xl: 0.5rem;
$border-radius-2xl: 0.5rem;

$box-shadow: 0.5rem 0.5rem 1rem rgba($black, .15) !default;
$box-shadow-sm: 0.125rem 0.125rem .25rem rgba($black, .075) !default;
$box-shadow-lg: 1rem 1rem 2rem rgba($black, .175) !default;
$box-shadow-inset: inset 1px 1px 2px rgba($black, .075) !default;

$tooltip-color: #fff;
$tooltip-bg: $dark;